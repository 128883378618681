<template>
  <div class="messageSend-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="messageSends">
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('messageSend.content','消息内容')">
            <el-input v-model.trim="filter.content" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="content" :label="$l('messageSend.content','消息内容')"></el-table-column>
      <el-table-column prop="receiverName" :label="$l('messageSend.receiver','接收人姓名')" align="center"></el-table-column>
      <el-table-column prop="receiverPhone" :label="$l('messageSend.receiver','接收人手机')" align="center"></el-table-column>
      <el-table-column prop="createTime" :label="$l('common.createTime','创建时间')" align="center"></el-table-column>
      <el-table-column prop="isRead" :label="$l('messageSend.isRead','是否已读')" align="center">
        <template slot-scope="scope">
          {{scope.row.isRead?$l("messageSend.yes","是"):$l("messageSend.no","否")}}
        </template>
      </el-table-column>
    </vm-table>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        filter: {
          content: "",
          type: "sms",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>